import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Import your firestore setup
import './AccountDetails.css'; // Create a corresponding CSS file
import { Link } from 'react-router-dom';

const AccountDetails = () => {
  const { accountId } = useParams();
  const [accountData, setAccountData] = useState(null);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        // Fetch account details
        const accountRef = doc(firestore, 'accounts', accountId);
        const accountSnap = await getDoc(accountRef);

        if (accountSnap.exists()) {
          const accountInfo = accountSnap.data();
          
          // Fetch orders associated with this account
          const ordersCollectionRef = collection(firestore, 'accounts', accountId, 'orders');
          const ordersSnapshot = await getDocs(ordersCollectionRef);
          const fetchedOrders = [];

          for (const orderDoc of ordersSnapshot.docs) {
            const date = orderDoc.id; // DD-MM-YYYY format
            const orderIds = Object.keys(orderDoc.data());
            
            for (const orderId of orderIds) {
              const orderRef = doc(firestore, 'orders', date, 'orders', orderId);
              const orderSnap = await getDoc(orderRef);
              if (orderSnap.exists()) {
                fetchedOrders.push({ id: orderId, date, ...orderSnap.data() });
              }
            }
          }

          // Sort the fetched orders first by date (descending), then by time (descending)
          fetchedOrders.sort((a, b) => {
            const dateA = new Date(a.date.split('-').reverse().join('-')); // Convert to YYYY-MM-DD for proper sorting
            const dateB = new Date(b.date.split('-').reverse().join('-'));

            if (dateA.getTime() !== dateB.getTime()) {
              return dateB - dateA; // Sort by date descending
            } else {
              return b.createdAt.localeCompare(a.createdAt); // Sort by time descending
            }
          });

          setAccountData(accountInfo);
          setOrders(fetchedOrders);
        }
      } catch (error) {
        console.error("Error fetching account details or orders:", error);
      }
    };

    fetchAccountData();
  }, [accountId]);

  if (!accountData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="account-details">
      <h1>Detalii Cont</h1>
      <div className="account-info">
        <h2>{accountData.name}</h2>
        <p><strong>Telefon:</strong> {accountData.phone}</p>
        <p><strong>Email:</strong> {accountData.mail || 'N/A'}</p>
        <p><strong>Adresă:</strong> {accountData.street 
          ? `${accountData.street} ${accountData.number || ''}, ${accountData.apartment ? `Ap. ${accountData.apartment}, ` : ''}${accountData.localitate || ''}`
          : 'N/A'}</p>
      </div>
      <h2>Comenzi</h2>
      <table className="orders-table">
        <thead>
          <tr>
            <th>Data</th>
            <th>Creat la ora</th>
            <th>Preț Total</th>
            <th>Status</th>
            <th>Detalii</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.id}>
              <td>{order.date}</td>
              <td>{order.createdAt}</td>
              <td>{order.totalPrice} RON</td>
              <td>
                {order.status === -1 && 'Comanda Anulată'}
                {order.status === 0 && 'Comanda Primită'}
                {order.status === 1 && 'Comanda Acceptată'}
                {order.status === 2 && 'Comanda se Pregătește'}
                {order.status === 3 && 'Comanda se Gătește'}
                {order.status === 4 && 'Comanda se Livrează'}
                {order.status === 5 && 'Comanda Finalizată'}
              </td>
              <td><Link to={`/admin/comenzi/${order.date}/${order.id}`}>Detalii</Link></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccountDetails;
