import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { firestore } from '../../firebase';
import './Soferi.css';
import RouteMapModal from './RouteMapModal';

const Soferi = () => {
  const [drivers, setDrivers] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);

  useEffect(() => {
    const fetchDevices = async () => {
      const devicesCollection = collection(firestore, "locations");
      const devicesSnapshot = await getDocs(devicesCollection);
      const devicesData = devicesSnapshot.docs.map(doc => ({
        id: doc.id,
        plate: doc.data().plate
      }));
      setDrivers(devicesData);
    };

    fetchDevices();
  }, []);

  const fetchRoutes = async (deviceId) => {
    setSelectedDevice(deviceId);
    const routesCollection = collection(firestore, `locations/${deviceId}/routes`);
    const routesQuery = query(routesCollection, orderBy("start_time", "desc"));
    const routesSnapshot = await getDocs(routesQuery);
    const routesData = routesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setRoutes(routesData);
  };

  return (
    <div className="soferi">
      <h1>Driver Routes</h1>
      <select onChange={(e) => fetchRoutes(e.target.value)} value={selectedDevice || ''}>
        <option value="" disabled>Select a device by plate</option>
        {drivers.map(driver => (
          <option key={driver.id} value={driver.id}>{driver.plate}</option>
        ))}
      </select>

      {routes.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Start Time</th>
              <th>End Time</th>
            </tr>
          </thead>
          <tbody>
            {routes.map(route => (
              <tr key={route.id} onClick={() => setSelectedRoute(route)}>
                <td>{new Date(route.start_time.seconds * 1000).toLocaleString()}</td>
                <td>{route.end_time ? new Date(route.end_time.seconds * 1000).toLocaleString() : "ACTIV"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {selectedRoute && (
        <RouteMapModal 
          route={selectedRoute} 
          onClose={() => setSelectedRoute(null)} 
        />
      )}
    </div>
  );
};

export default Soferi;
