import React from 'react';
import { useLocation } from 'react-router-dom';
import './NotificationBar.css';

const NotificationBar = ({ isAdmin, isManager }) => {
    const location = useLocation();

    // Conditionally render the bar based on the current route
    if (isAdmin && location.pathname.startsWith('/admin')) {
        return null;
    }
    if (isManager && location.pathname.startsWith('/management')) {
        return null;
    }

    if(!isAdmin && !isManager) {
        return null;
    }

    return (
        <div className="notification-bar">
            {isAdmin && (
                <a href="/admin" className="notification-link">
                    Ești conectat ca administrator. Accesează panoul de administrare
                </a>
            )}
            {isManager && !isAdmin && (
                <a href="/management/orders" className="notification-link">
                    Ești conectat ca ospătar. Accesează panoul de management
                </a>
            )}
        </div>
    );
};

export default NotificationBar;
