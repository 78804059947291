import React, { useState, useEffect, useRef } from 'react';
import { collection, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './Management.css';
import Modal from 'react-modal'; // For the cancellation reason modal
import buzzSound from '../../assets/buzz.mp3'; // Replace with your actual path
import moment from 'moment-timezone';

const ManagementOrders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reason, setReason] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [currentDate, setCurrentDate] = useState(moment().tz('Europe/Bucharest').format('DD-MM-YYYY'));
    const prevOrdersRef = useRef([]);
    const audioRef = useRef(new Audio(buzzSound));
    const [hasInteracted, setHasInteracted] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            // Dynamically get today's date in DD-MM-YYYY format
            const todayDate = moment().tz("Europe/Bucharest").format('DD-MM-YYYY');

            const ordersCollectionRef = collection(firestore, 'orders', todayDate, 'orders');

            // Set up real-time listener for incoming orders
            const unsubscribe = onSnapshot(ordersCollectionRef, (snapshot) => {
                const updatedOrders = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Sort the orders by createdAt
                updatedOrders.sort((a, b) => {
                    return b.createdAt.localeCompare(a.createdAt);
                });

                const newWaitingOrders = updatedOrders.filter(order => order.status === 0 && !prevOrdersRef.current.some(prevOrder => prevOrder.id === order.id));
                if (newWaitingOrders.length > 0) {
                    startPlayingBuzzSound();
                } else {
                    stopPlayingBuzzSound();
                }

                setOrders(updatedOrders);
                prevOrdersRef.current = updatedOrders;
                setLoading(false);
            });

            return () => unsubscribe();
        };
        fetchData();

        const intervalId = setInterval(() => {
            const newDate = moment().tz('Europe/Bucharest').format('DD-MM-YYYY');
            if (newDate !== currentDate) {
                setCurrentDate(newDate);
                fetchData();
            }
        }, 1800000); // Check if date changed every 30 minutes

        return () => clearInterval(intervalId);
    }, [currentDate]);

    const startPlayingBuzzSound = () => {
        if (audioRef.current.paused) {
            audioRef.current.loop = true;
            audioRef.current.play().catch(error => {
                console.error('Autoplay was prevented:', error);
            });
        }
    };

    const stopPlayingBuzzSound = () => {
        audioRef.current.loop = false;
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
    };

    const getStatusText = (status) => {
        switch (status) {
            case -1:
                return 'Comanda anulată';
            case 1:
                return 'Comanda acceptată';
            case 2:
                return 'Comanda în pregătire';
            case 3:
                return 'Comanda se gătește';
            case 4:
                return 'Comanda se livrează';
            case 5:
                return 'Comanda finalizată';
            default:
                return 'Status necunoscut';
        }
    };


    const handleAccept = async (orderId) => {
        const todayDate = moment().tz('Europe/Bucharest').format('DD-MM-YYYY');
        const orderDocRef = doc(firestore, 'orders', todayDate, 'orders', orderId);
        await updateDoc(orderDocRef, { status: 1 }); // 1 indicates accepted

        try {
            const response = await fetch('https://sendemail-sp4bg7dmba-lm.a.run.app/sendEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    orderId: orderId,
                    status: 'Acceptată',
                }),
            });
        } catch (error) {
            console.error('Error sending email:', error);
        };

        // Move the order to Today's Orders section
        setOrders(prevOrders =>
            prevOrders.map(order =>
                order.id === orderId ? { ...order, status: 1 } : order
            )
        );
    };

    const handleCancel = async () => {
        if (!reason) {
            alert("Vă rugăm să furnizați un motiv pentru anulare.");
            return;
        }
        const todayDate = new Date().toLocaleDateString('en-GB').split('/').join('-');
        const orderDocRef = doc(firestore, 'orders', todayDate, 'orders', selectedOrderId);
        await updateDoc(orderDocRef, { status: -1, reason }); // -1 indicates canceled

        try {
            const response = await fetch('https://sendemail-sp4bg7dmba-lm.a.run.app/sendEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    orderId: selectedOrderId,
                    status: 'Refuzată',
                    reason: reason,
                }),
            });
        } catch (error) {
            console.error('Error sending email:', error);
        }


        // Move the order to Today's Orders section
        setOrders(prevOrders =>
            prevOrders.map(order =>
                order.id === selectedOrderId ? { ...order, status: -1, reason } : order
            )
        );

        setReason('');
        closeModal();
    };

    const handleUserInteraction = () => {
        audioRef.current.play().catch(error => {
            console.error('Autoplay was prevented:', error);
        })
    };

    const handleUserClick = () => {
        setHasInteracted(true);
        // Play a silent audio to ensure future sounds can be played
        audioRef.current.play().then(() => {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        });
    };


    const openModal = (orderId) => {
        setSelectedOrderId(orderId);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setReason('');
        setSelectedOrderId(null);
    };

    if (loading) {
        return <p>Se încarcă comenzile...</p>;
    }

    return (
        <div className="management__orders" onClick={handleUserInteraction}>
            {!hasInteracted && (
                <div className="start-monitoring-banner">
                    <button onClick={handleUserClick} className="start-monitoring-button">
                        Apasă aici pentru a începe monitorizarea comenzilor
                    </button>
                </div>
            )}
            <h2>În așteptare</h2>
            <div className="incoming__orders">
                {orders.filter(order => order.status === 0).map(order => (
                    <div key={order.id} className="order__card">
                        <h3>Comanda #{order.id}</h3>
                        <p>Nume: {order.name}</p>
                        <p>Telefon: {order.phone}</p>
                        <p>Tip Livrare: {order.deliveryType === 'delivery' ? 'Livrare' : 'Ridicare'}</p>
                        <p>Preț Total: {order.totalPrice} RON</p>
                        <div className="order__items">
                            {order.items.map((item, index) => (
                                <p key={index}>{item.title} x {item.quantity}</p>
                            ))}
                        </div>
                        <div className="order__actions">
                            <button onClick={() => handleAccept(order.id)} className="admin-custom-button">Acceptă</button>
                            <button onClick={() => openModal(order.id)} className="admin-custom-button">Anulează</button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Cancellation Reason Modal */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Motivul Anulării"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <h2>Furnizați un motiv pentru anulare</h2>
                <textarea
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Motivul pentru anulare"
                    className="modal-textarea"
                />
                <button onClick={handleCancel} className="admin-custom-button">Trimite</button>
                <button onClick={closeModal} className="admin-custom-button">Închide</button>
            </Modal>

            <h2>Comenzi de astăzi</h2>
            <div className="today__orders">
                {orders.filter(order => order.status !== 0).map(order => (
                    <div key={order.id} className="order__card">
                        <h3>Comanda #{order.id}</h3>
                        <p>Nume: {order.name}</p>
                        <p>Telefon: {order.phone}</p>
                        <p>Tip Livrare: {order.deliveryType === 'delivery' ? 'Livrare' : 'Ridicare'}</p>
                        {order.deliveryType === 'delivery' && <p>Adresă: {order.address}</p>}
                        <p>Pret total: {order.totalPrice}</p>
                        {order.deliveryType === 'delivery' && <p>Metoda de plata: {order.paymentMethod}</p>}
                        <p>Creat la: {order.createdAt}</p>
                        <p>Status: {getStatusText(order.status)}</p>
                        <p>Produse: </p>
                        <div className="order__items">
                            {order.items.map((item, index) => (
                                <p key={index}>{item.title} x {item.quantity}</p>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ManagementOrders;
