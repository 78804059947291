// src/RouteMapModal.js
import React from 'react';
import { MapContainer, TileLayer, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './RouteMapModal.css';

const RouteMapModal = ({ route, onClose }) => {
  const positions = route.positions.map(pos => [pos.location.latitude, pos.location.longitude]);

  return (
    <div className="route-map-modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>Close</button>
        <MapContainer center={positions[0]} zoom={13} style={{ height: "400px", width: "100%" }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Polyline positions={positions} color="blue" />
        </MapContainer>
      </div>
    </div>
  );
};

export default RouteMapModal;
