import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { t } = useTranslation();

  const cartReducer = (state, action) => {
    switch (action.type) {
      case 'ADD_TO_CART':
        const existingItemIndex = state.findIndex(item => item.productId === action.item.productId);
        if (existingItemIndex !== -1) {
          const updatedCart = [...state];
          updatedCart[existingItemIndex].quantity += 1;
          toast.success(t('cart.itemQuantityIncreased', { item: action.item.title }), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return updatedCart;
        } else {
          toast.success(t('cart.itemAdded', { item: action.item.title }), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return [...state, { ...action.item, quantity: 1, ambalaj: action.item.ambalaj || 0 }];
        }
      case 'DECREASE_QUANTITY':
        const decreasedCart = state.map(item =>
          item.productId === action.id
            ? { ...item, quantity: item.quantity - 1 }
            : item
        ).filter(item => item.quantity > 0);
        if (decreasedCart.length < state.length) {
          const removedItem = state.find(item => item.productId === action.id);
          toast.info(t('cart.itemRemoved', { item: removedItem.title }), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return decreasedCart;
      case 'REMOVE_FROM_CART':
        const removedItem = state.find(item => item.productId === action.id);
        toast.info(t('cart.itemRemoved', { item: removedItem.title }), {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        return state.filter(item => item.productId !== action.id);
      case 'CLEAR_CART':
        return [];
      default:
        return state;
    }
  };

  const initialState = () => {
    const savedCart = localStorage.getItem('cart');
    try {
      return Array.isArray(JSON.parse(savedCart)) ? JSON.parse(savedCart) : [];
    } catch (e) {
      console.error("Could not parse cart from localStorage", e);
      return [];
    }
  };

  const [cart, dispatch] = useReducer(cartReducer, [], initialState);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
